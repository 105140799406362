<template>
  <template v-if="me">
    <v-list-item>
      <template #prepend>
        <app-avatar :full-name="me.full_name" :src="me.avatar_url" />
      </template>

      <v-list-item-title>{{ me.full_name }}</v-list-item-title>
      <v-list-item-subtitle>
        {{ me.email }}
      </v-list-item-subtitle>

      <template #append>
        <v-btn icon="ph:sign-out" variant="text" @click="logout" />
      </template>
    </v-list-item>
  </template>
</template>

<script lang="ts" setup>
const { me, logout } = useCurrentUser();
</script>
